.chat-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;

  .left,
  .right {
    display: flex;
    gap: 10px;
    width: 45%;
  }

  .right {
    justify-content: end;
    align-self: end;
  }
}
