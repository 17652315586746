.file-input-label {
  cursor: pointer;
  display: flex;
  background: var(--button-background);
  padding: 4px 8px;
  border-radius: 10px;
  color: var(--font-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border: 1px solid var(--button-background);

  &:has(:disabled) {
    color: var(--font-color-disabled);
    cursor: default;
  }
}

[data-theme="dark"] .file-input-label {
  border: 1px solid var(--font-color);
  &:has(:disabled) {
    border-color: var(--font-color-disabled);
  }
}
