.message {
  background: var(--chat-window-color);
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.3rem;
  display: flex;
  align-items: flex-start;
}

.truncated-warning {
  color: grey;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.user-message + .user-message {
  margin-top: 2rem;
}

@media (min-width: 1024px) {
  .message {
    padding: 1.5rem 2.2rem;
  }
}

.bot-message {
  background: var(--background-color);
}

.chat-img {
  color: var(--napa-blue);
  object-fit: cover;
  width: 2.7rem;
  margin-right: 1.5rem;
}

.chat-markdown {
  word-break: break-word;
}

.message-file {
  margin-bottom: 10px;
}

.chat-markdown p {
  padding: 0.3rem 0 0.3rem 0;
}

.chat-markdown h1,
.chat-markdown h2,
.chat-markdown h3 {
  padding: 0.3rem 0 0.3rem 0;
}

.chat-markdown > *:first-child {
  padding-top: 0;
}

.chat-markdown ul {
  list-style: disc;
  width: 95%;
  margin: 1rem 0 1rem 3rem;
}

.chat-markdown ul li {
  width: 95%;
  margin: 10px 0 10px 0;
}

.chat-markdown th {
  background-color: var(--chat-window-color);
  border: 1px solid var(--table-border);
  padding: 8px;
  text-align: center;
}
.chat-markdown td {
  border: 1px solid var(--table-border);
  padding: 8px;
}

.code-container {
  position: relative;
}

.copy-code-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
