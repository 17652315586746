.page-informative-container {
  background-color: #f6f6f6;
  overflow-y: auto;
  height: 100vh;
}

.page-informative-container main {
  padding: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-inline: 1.3rem;
}

@media (min-width: 1024px) {
  .page-informative-container main {
    padding: 40px 82px;
    max-width: 1014px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-informative-container header {
  padding-top: 10px;
}

.page-informative-container footer,
.page-informative-container footer a {
  color: #000;
}

.page-informative-container footer {
  width: 100%;
  text-align: right;
  padding: 24px 20px;
  justify-content: flex-end;
}
