.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .file-preview {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--button-background);
    background: var(--button-background);
    color: var(--font-color);
    font-weight: 700;
  }

  .file-info {
    margin: 0 15px 0 10px;
  }

  .preview-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .file-name {
    font-size: 14px;
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .file-type {
    color: var(--font-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
}

[data-theme="dark"] .file-preview {
  border: 1px solid var(--font-color);
}
