/* define variables default to dark theme */
:root {
  --notify-text-color-error: #ff2c10;
  --notify-icon-color-error: #ffe0d4;
  --gpc-white: #ffffff;
  --gpc-dark: #202020;
  --gpc-grey: #a4a9ad;
  --gpc-red: #fb3449;
  --gpc-red-dark: #ff2c10;
  --gpc-blue: #d1d3d3;
}

:root [data-theme="dark"] {
  --background-color: #03001f;
  --sidebar-color: #010009;
  --font-color: white;
  --border-color: 1px solid #646464;
  --chat-window-color: #1c1e3a;
  --napa-blue: white;
  --font-color-disabled: var(--gpc-grey);
  --semantic-action-color-foreground-neutral-white: transparent;
  --napa-blue-shadow: #fff2;
  --table-border: #ccc;
  --button-background: #1c1e3a;
  --gpc-close-icon-background: var(--font-color);
  --gpc-clip-icon-background: var(--gpc-white);
}

:root [data-theme="light"] {
  --background-color: #fff;
  --sidebar-color: #f6f6f6;
  --font-color: #000000;
  --font-color-disabled: var(--gpc-grey);
  /*--border-color: black;*/
  --chat-window-color: #f6f6f6;
  --napa-blue: #001489;
  --napa-blue-shadow: #00148922;
  --table-border: #ccc;
  --button-background: #efefef;
  --notify-background-color-error: #ffe0d4;
  --gpc-close-icon-background: var(--font-color);
  --gpc-clip-icon-background: var(--gpc-dark);
}

html {
  overflow: hidden;
}

.theme-container {
  height: 100vh;
  background-color: var(--background-color);
  color: var(--font-color);
  font-family: sans-serif;
}

p {
  white-space: pre-wrap;
  padding: var(--brand-spacing-40);
}

/* Override @bolt */
.tw-bg-semantic-action-background-neutral-white {
  background-color: transparent !important;
}

.tw-text-semantic-action-foreground-neutral-white {
  color: var(--background-color) !important;
}
