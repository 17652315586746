.Toastify__toast-theme--colored.Toastify__toast.Toastify__toast--error {
  padding: 12px;
  color: var(--notify-text-color-error);
  background: var(--notify-icon-color-error);
}

.notification-container {
  display: flex;
  gap: 12px;
}
